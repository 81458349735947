<template>
  <div>
    <!-- 教师 整卷表单 -->
    <el-form :model="form"
             :rules="rules"
             ref="formRef"
             label-width="80px">
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="试卷名称">
            <el-input clearable
                      placeholder="请输入"
                      style="width:100%"
                      v-model="form.paper_name" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="指定年级"
                        prop="given_garde">
            <el-select v-model="form.given_garde"
                       clearable
                       style="width:100%"
                       @change="gradeChange"
                       placeholder="选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="试卷科目"
                        prop="subjectId">
            <el-select v-model="form.subjectId"
                       style="width:100%"
                       @change="subjectChage"
                       placeholder="选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷地区"
                        prop="district_id">
            <el-select v-model="form.district_id"
                       style="width:100%"
                       @change="changeDir"
                       placeholder="选择">
              <el-option v-for="item in districtList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷来源"
                        prop="source_id">
            <el-select v-model="form.source_id"
                       filterable
                       @change="getYearList"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in sourceList"
                         :key="item.source_id"
                         :label="item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="试卷年份"
                        prop="question_year">
            <el-select v-model="form.question_year"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in year"
                         :key="item.question_year"
                         :label="item.question_year"
                         :value="item.question_year">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="10"
              style="text-align:center;margin-top:20px">
        <el-col :span="24">
          <el-button type="primary"
                     @click="toInfo">提交</el-button>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {},
      width: '900px',
      showList: [],
      testingList: [],
      subjectList: [],
      paperTypeList: [],
      gradeList: [],
      classList: [],
      sourceList: [],
      districtList: [],
      rules: {
        paper_name: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        source_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        question_year: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

        given_garde: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

      },
      year: [],

    }
  },
  mounted () {
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
  },
  methods: {
    // getGradeAndClass () {
    //   this.$http({
    //     url: '/api/v1/public/subject_get_grade_and_class',
    //     method: 'get',
    //     params: {
    //       subject_id: this.form.subjectId
    //     }
    //   }).then(res => {
    //     this.gradeList = res.data.grade_data
    //     if (!this.form.given_garde) {
    //       // this.form.given_garde = this.gradeList[0].grade_id
    //       this.$set(this.form, 'given_garde', this.gradeList[0].grade_id)
    //     }
    //     this.gradeChange()
    //   })
    // },

    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId
        form.permissions = 1
        this.$http({
          url: '/api/v1/combination/whole_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });

          this.$router.push('/testPapercomposition/onlyPaper/info?edu_paper_id=' + res.data.edu_paper_id + '&module=3')

          // this.$router.push('/testPapercomposition/autonomously/addDetails?edu_paper_id=' + res.data.edu_paper_id)
        })
      })
    },
    getDirList () {
      this.$http({
        url: '/api/v1/public/select_district',
        method: 'get',
      }).then(res => {
        this.districtList = Object.values(res.data)
        if (!this.form.district_id && this.districtList.length > 0) {
          this.form.district_id = this.districtList[0].paper_district_id
        }
        this.changeDir()
      })
    },
    getYearList () {
      this.$http({
        // url: '/api/v1/public/year_all',
        url: 'api/v1/public/select_source_year',
        method: 'get',
        params: {
          source_id: this.form.source_id
        }
      }).then(res => {
        this.year = res.data
        if (this.year && this.form.question_year && (this.year.filter(item => item.question_year == this.form.question_year)).length == 0) {
          this.form.question_year = this.year[0].question_year
        }
      })
    },
    addRules () {
      this.subjectList = []
      // if (this.form.source_id > 3) return
      this.form.subjectId = ''
      this.getSubjectList()
    },

    subjectChage () {
      this.getSourceList()

    },
    changeDir () {
      this.getSourceList()
    },
    gradeChange () {
      if (this.form.given_garde == '') return
      this.addRules()
      // this.subjectChage()
    },

    getSourceList () {
      let params = {
        subject_id: this.form.subjectId,
        district_id: this.form.district_id,
        grade_id: this.form.given_garde
      }
      this.$http({
        // url: '/api/v1/public/sum_up_source',
        url: '/api/v1/public/source_for_ds',
        method: 'get',
        params
      }).then(res => {
        this.sourceList = res.data
        if (this.sourceList && this.sourceList.length && this.form.source_id && (this.sourceList.filter(item => item.source_id == this.form.source_id)).length == 0) {
          this.form.source_id = this.sourceList[0].source_id
        }
        this.getYearList()
        // this.getOther()
      })
    },
    getOther () {
      this.$http({
        url: '/api/v1/file/paper_source',
        method: 'get',
      }).then(res => {
        res.data.list.forEach(item => {
          this.sourceList.push({
            source_name: item.paper_name,
            source_id: item.source_id
          })
        });
      })
    },
    getList () {
      this.getGradeList()
      this.getDirList()
      // this.getSourceList()
      // this.getYearList()
    },
    getSubjectList () {
      if (!this.form.given_garde) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde,
          // class_id: this.form.given_class
        }
      }).then(res => {
        this.subjectList = Object.values(res.data)
        if (!this.form.subjectId) {
          this.form.subjectId = this.subjectList[0].subject_id
        }
        this.subjectChage()
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
        if (!this.form.given_garde) {
          this.form.given_garde = this.gradeList[0].grade_id
        }
        this.gradeChange()
      })
    },

    handleClose () {
      this.form.source_id = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>