<template>
  <div>
    <el-dialog :title="title"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">

      <wholeForm ref="wholeFormRef"
                 v-show="type== 3"
                 @handleClose="handleClose" />

      <knowledgeForm ref="knowledgeFormRef"
                     v-show="type==4 "
                     @handleClose="handleClose" />
      <chapterForm ref="chapterFormRef"
                   v-show="type==5"
                   key='add'
                   @handleClose="handleClose" />
      <templateForm ref="templateFormRef"
                    v-show="type==6"
                    @handleClose="handleClose" />

    </el-dialog>
  </div>
</template>

<script>
import wholeForm from './wholeForm.vue'
import chapterForm from './chapterForm.vue'
import knowledgeForm from './knowledgeForm.vue'
import templateForm from './templateForm.vue'

// import { createPaper } from '@/api/IntelligentTest.js'
export default {
  data () {
    return {
      dialogVisible: false,
      isShow_to: false,
      form: {},
      year: (new Date()).getFullYear(),
      width: '800px'

    }
  },
  props: {
    // 接收题目，在使用组件的地方去循环
    title: {
      type: String,
      default: ''
    },
    type: {//3：整卷，4：知识点，5：章节
      type: String,
      default: ''
    },
  },
  created () {
    // this.getDistrictList()
  },
  components: { wholeForm, chapterForm, knowledgeForm, templateForm },

  mounted () {
  },
  watch: {
    // dialogVisible: {
    //   handler (newVal, oldVal) {
    //     if (newVal) {
    //       this.$nextTick(() => {
    //         // this.$refs.PaperFormRef.form = this.form
    //         this.$set(this.$refs.PaperFormRef, 'form', this.form)
    //         this.$refs.PaperFormRef.initData()
    //       })
    //     }
    //   }
    // }
  },
  methods: {

    initData () {
      this.$nextTick(() => {
        switch (this.type) {
          case '3': this.initWholePaper(); break;
          case '4': this.initKnowledgePaper(); break;
          case '5': this.initChapterPaper(); break;
          case '6': this.initTemplatePaper(); break;
        }
      })
    },


    initWholePaper () {
      let today = new Date()
      let year = today.getFullYear()
      let form = {
        paper_name: '',
        source_id: 1,
        question_year: year - 1 + '',
        given_garde: '',
        paper_duration: '',
        subjectId: '',
        district_id: '',
      }
      this.$refs.wholeFormRef.form = form
      this.$refs.wholeFormRef.getList()

    },

    initKnowledgePaper () {
      let form = {
        paper_name: '',
        knowledge_id: [],
        question_num: 10,
        difficulty: '',
        source_id: 1,
        question_year: this.year + '',
        paper_district: '',
        given_garde: '',
        subjectId: '',
      }
      this.$refs.knowledgeFormRef.form = form
      this.$refs.knowledgeFormRef.getList()
    },
    initChapterPaper () {
      let form = {
        paper_name: '',
        subject_id: '',
        district_id: '',
        chapter_id: [],
        given_garde: '',
        question_num: 10,
        difficulty: '',
        question_year: this.year + '',
        source_id: 1,
        textbook_id: '',
      }
      this.$refs.chapterFormRef.form = form
      this.$refs.chapterFormRef.getList()
    },

    initTemplatePaper () {
      let form = {
        paper_name: '',
        subject_id: '',
        paper_district_id: ''
      }
      this.$refs.templateFormRef.form = form
      this.$refs.templateFormRef.getList()
    },


    handleClose () {
      this.dialogVisible = false
    },
  }
}
</script>

<style lang="scss" scoped>
.hide_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}
.question_number {
  position: relative;

  .question_number_select {
    padding: 12px 12px 8px;
    margin-top: 10px;
    background: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 3px;
    box-shadow: 0px 2px 12px #ddd;
    position: absolute;
    z-index: 1;
    width: max-content;
    max-width: 350px;
    text-align: right;
  }
  .question_number_arrow {
    z-index: 2;
    position: absolute;
    display: block;
    border-bottom: 8px solid #dfdfdf;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: 39px;
    left: 20px;
    &::before {
      position: absolute;
      display: block;
      content: "";
      border-bottom: 8px solid #ffffff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      top: 1px;
      left: -8px;
    }
  }
}
.year_component {
  margin-top: 10px;
  position: absolute;
  z-index: 999;
}
</style>