<template>
  <div>
    <el-form :model="form"
             :rules="rules"
             ref="formRef"
             label-width=80px>
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="试卷名称">
            <el-input clearable
                      placeholder="请输入"
                      style="width:100%"
                      v-model="form.paper_name" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="指定年级"
                        prop="given_garde">
            <el-select v-model="form.given_garde"
                       clearable
                       style="width:100%"
                       @change="gradeChange"
                       placeholder="选择">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷科目"
                        prop="subjectId">
            <!-- getKnowledgeList -->
            <el-select v-model="form.subjectId"
                       style="width:100%"
                       @change="subjectChage"
                       placeholder="选择">
              <el-option v-for="item in subjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷地区"
                        prop="district_id">
            <el-select v-model="form.district_id"
                       style="width:100%"
                       filterable
                       placeholder="选择">
              <el-option v-for="item in districtList"
                         :key="item.paper_district_id"
                         :label="item.paper_district_name"
                         :value="item.paper_district_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷年份"
                        prop="question_year">
            <el-select v-model="form.question_year"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in year"
                         :key="item"
                         :label="item"
                         :value="item">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="试卷来源">
            <el-select v-model="form.source_id"
                       style="width:100%"
                       placeholder="选择">
              <el-option v-for="item in sourceList"
                         :key="item.source_id"
                         :label="item.source_name"
                         :value="item.source_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="知识点">
            <div class="zhishidian">
              <el-cascader v-model="form.knowledge_id"
                           ref="cascaderRef"
                           :options="knowledgeList"
                           :props="props"></el-cascader>
            </div>

          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="题目数量"
                        prop="question_num">
            <el-input clearable
                      placeholder="请输入"
                      style="width:100%"
                      v-model="form.question_num" />
          </el-form-item>
        </el-col>

        <el-col :span="8">
          <el-form-item label="题目难度">
            <el-select v-model="form.difficulty"
                       style="width:100%"
                       clearable
                       placeholder="选择">
              <el-option v-for="item in difficultyList"
                         :key="item.val"
                         :label="item.text"
                         :value="item.val">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>

      <el-row :gutter="10"
              style="text-align:center;margin-top:20px">
        <el-col :span="24">
          <el-button type="primary"
                     @click="toInfo">提交</el-button>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
export default {
  data () {
    return {
      width: '900px',
      form: {},

      subjectList: [],
      gradeList: [],
      sourceList: [],
      knowledgeList: [],
      districtList: [],
      difficultyList: [],
      year: [],
      rules: {
        subjectId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        district_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

        given_garde: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        question_year: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

      },
      props: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
      }
    }
  },
  mounted () {
    if (document.body.clientWidth < 1200) {
      this.width = '700px'
    }
  },
  methods: {
    toInfo () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = JSON.parse(JSON.stringify(this.form))
        form.subject_id = form.subjectId
        delete form.subjectId
        // form.knowledge_id = form.knowledge_id.length ? Array.from(new Set(form.knowledge_id.join(',').split(',').map(item => Number(item)))) : []
        let ids = []
        if (Array.isArray(form.knowledge_id)) {
          form.knowledge_id.forEach(item => {
            ids.push(item[item.length - 1])
          });
          form.knowledge_id = ids
        }

        this.$http({
          url: '/api/v1/combination/knowledge_create',
          method: 'post',
          data: form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '操作成功!',
            type: 'success'
          });
          this.$router.push('/testPapercomposition/onlyPaper/info?edu_paper_id=' + res.data.edu_paper_id + '&module=4')

          // this.$router.push('/testPapercomposition/testingCentre/info?edu_paper_id=' + res.data.edu_paper_id)
        })
      })
    },
    getDirList () {
      this.$http({
        url: '/api/v1/public/select_district',
        method: 'get',
      }).then(res => {
        this.districtList = Object.values(res.data)
        if (!this.form.district_id) {
          // this.form.district_id = this.districtList[0].paper_district_id
          this.$set(this.form, 'district_id', this.districtList[0].paper_district_id)
        }
      })
    },
    getKnowledgeList () {
      this.form.knowledge_id = []
      this.$http({
        url: '/api/v1/question/knowledge',
        method: 'get',
        params: {
          subject_id: this.form.subjectId
        }
      }).then(res => {
        this.knowledgeList = res.data.list
      })
    },
    getSourceList () {

      this.$http({
        url: '/api/v1/public/sum_up_source',
        method: 'get',
      }).then(res => {
        this.sourceList = res.data
      })
    },
    getYearList () {
      this.$http({
        url: '/api/v1/public/year_all',
        method: 'get',
      }).then(res => {
        this.year = res.data
      })
    },

    gradeChange () {

      this.form.subjectId = ''
      this.subjectList = []
      if (this.form.given_garde == '') return
      this.getSubjectList()
    },

    getList () {
      this.getGradeList()
      this.getDifficultyList()
      this.getDirList()
      this.getYearList()
      this.getSourceList()
    },

    getSubjectList () {
      if (!this.form.given_garde) {
        return
      }
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          grade_id: this.form.given_garde
        }
      }).then(res => {
        this.subjectList = Object.values(res.data)
        if (!this.form.subjectId) {
          this.form.subjectId = this.subjectList[0].subject_id
        }
        this.getKnowledgeList()
      })
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
        if (!this.form.given_garde) {
          this.form.given_garde = this.gradeList[0].grade_id
        }
        this.gradeChange()
      })
    },
    subjectChage () {
      this.knowledgeList = []

      if (this.form.given_garde == '' && this.form.subjectId == '') return
      var form = {
        grade_id: this.form.given_garde,
        subject_id: this.form.subjectId
      }

      if (this.form.subjectId == '') {
        return
      }
      this.getKnowledgeList()
    },


    getDifficultyList () {
      this.$http({
        url: '/api/v1/combination/question_difficulty',
        method: 'get'
      }).then(res => {
        this.difficultyList = res.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 98%;
  margin: 0 auto;
  border-top: 1px solid #ddd;
  font-size: 14px;
  div {
    margin-left: 15px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}

.zhishidian {
  height: 33px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  border-radius: 4px;
  ::v-deep .el-cascader__tags .el-tag:not(.is-hit) {
    height: 33px;
  }
  ::v-deep .el-cascader__tags .el-tag {
    background-color: #ffffff;
  }
  ::v-deep .el-cascader__tags {
    height: 33px;
  }
  ::v-deep .el-icon-close {
    display: none;
  }
}
</style>